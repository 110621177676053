import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageServices from "containers/PageServices/index";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";

import ProductsPage from "containers/ProductPage/ProductPage";
import ProductCategoryPage from "containers/ProductPage/ProductCategoryPage";
import ProductDetailPage from "containers/ProductDetailPage/index";
import Cart from "containers/CartPage/CartPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PaymentConfirmPage from "containers/PaymentConfirmPage/PaymentConfirmPage";
import PageBlogList from "containers/PageBlogList/PageBlogList";
import PageBlogDetail from "containers/PageBlogList/PageBlogDetail";


import Carousel from "components/Carousel/index";

export const pages: Page[] = [
	{ path: "/", exact: true, component: ProductsPage },
	{ path: "/category/:id", exact: true, component: ProductCategoryPage },
	{ path: "/product-detail/:id", component: ProductDetailPage },
	{ path: "/cart", component: Cart },
	{ path: "/checkout", component: CheckOutPage },
	{ path: "/payment-result", component: PaymentConfirmPage },
	{ path: "/account", component: AccountPage },
	{ path: "/blogs", component: PageBlogList },
	{ path: "/blog", component: PageBlogDetail },
	{ path: "/contact", component: PageContact },
	{ path: "/about", component: PageAbout },
	{ path: "/services", component: PageServices },
	{ path: "/signup", component: PageSignUp },
	{ path: "/login", component: PageLogin },
	{ path: "/forgot-pass", component: PageForgotPassword },
];

const Routes = () => {

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Header />
			<Carousel />
			<Switch>
				{pages.map(({ component, path, exact }) => {
					return (
						<Route
							key={path}
							component={component as any}
							exact={!!exact}
							path={path}
						/>
					);
				})}
				<Route component={Page404} />
			</Switch>
			<Footer />
		</BrowserRouter>
	);
};

export default Routes;
