import React, { FC, useState, useEffect, useContext } from "react";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/authenticate";
import { getKeyByLang, getPureArray } from "utils/util";
import { IProductInfo } from "../index";
import { commonAxiosPostRequest } from "utils/request";


export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
};

const PromoDetailRender: FC<ComponentProps> = ({ className = "", productInfo }) => {
    const { userInfo } = useContext(AuthContext);
    const { t } = useTranslation();
    const { language } = i18n;
    const [promotionDescriptionList, setPromotionDescriptionList] = useState<any[]>([]);

    const handleQueryPromotionDescriptionList = async () => {
        const [error, response] = await commonAxiosPostRequest<{ list: any[] }, {}>('/universal_mall/api/app/common/discountList', {});
        const list = getPureArray<any>(response?.list);
        setPromotionDescriptionList(list);
    }

    useEffect(() => {
        handleQueryPromotionDescriptionList();
    }, []);

    if (!promotionDescriptionList.length || userInfo?.userType === 2) {
        return null;
    }

    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">{t('product.promo_title')}</h2>
            <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
                {promotionDescriptionList.map((item: any) => {
                    return (
                        <React.Fragment key={item.id}>
                            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">{item?.[getKeyByLang(language, 'discountName')] || item?.discountName || '-'}</span>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default PromoDetailRender;