import Axios from "axios";
import { message } from 'antd';
import { RequestBaseUrl, TokenLocalStorageKey } from "utils/constant";
// import * as toast from "react-hot-toast";

export const enum ResponseStatusCode {
	OPERATING_SUCCESSFULLY = 200,
	UNAUTHORIZED = '2002',
	FORBIDDEN = '2003',
	NOT_FOUND = '2004',
	REQUEST_TIMEOUT = '2006',
	SYSTEM_ERROR = '3000',
}

export interface CommonApiResponse<T = any> {
	category: string;
	code: ResponseStatusCode;
	data: T,
	message: string;
}

export type CommonApiError = null | ({
	message: string;
	code: string | number;
});

export type CommonApiOptions = {
	showLoading?: boolean;
	timeout?: number;
}

Axios.defaults.baseURL = RequestBaseUrl;

const axiosInstance = Axios.create({
	baseURL: RequestBaseUrl,
	timeout: 100000,
	// withCredentials: true,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json; charset=utf-8'
	},
	params: {},
	data: {}
});

axiosInstance.interceptors.request.use((config: any) => {
	const token = localStorage.getItem('accessToken');
	if (token) {
		config.headers.Authorization = `${token}`;
	}
	return config;
}, error => {
	Promise.reject(error)
});

axiosInstance.interceptors.response.use((response: any): any => {
	const { message, code } = response?.data || {};
	// || code === '2003'
	// if ((message === 'Unauthorized') && !NotAuthPages.includes(window.location.pathname)) {
	// 	toast.error("Authentication has expired，please login again");
	// 	const redirect = encodeURIComponent(`${window.location.pathname}${window.location.search}`)
	// 	window.location.href = `${window.location.origin}/signin?redirect=${redirect}`;
	// }
	return response;
}, error => {
	return Promise.reject(error)
});

export const postReq = async (path: string, data: any, token?: string) => {
	return await Axios.post(path, data, token ? {
		headers: {
			'Authorization': `${token}`
		}
	} : {});
}

/**
 * 通用Post接口请求
 * @returns {Promise<[CommonApiError, T | null]>}
 */
export async function commonAxiosPostRequest<T, Q>(path: string, params?: Q, options?: CommonApiOptions): Promise<[CommonApiError, T | null]> {
	let tid;
	if (options?.showLoading) {
		// tid = message.loading('Loading...');
	}
	try {
		const token = localStorage.getItem(TokenLocalStorageKey);
		const result = await axiosInstance.post<Q, ({ data: CommonApiResponse<T> })>(path, params || {}, {
			headers: { Authorization: token ? `${token || ''}` : '' },
			...(options?.timeout ? { timeout: options.timeout } : {}),
		});
		const { code, data, message } = result?.data || {};
		if (code === ResponseStatusCode.OPERATING_SUCCESSFULLY) {
			return [null, data];
		}
		const errorMessage = (typeof data === 'string' && data ? data : message);
		return [{ message: errorMessage, code }, null];
	} catch (error: any) {
		const { code, message } = error || {};
		return [{ message: message || 'request error', code }, null];
	} finally {
		// tid && message.destroy();
	}
}

/**
 * 通用Get接口请求
 * @returns {Promise<[CommonApiError, T | null]>}
 */
export async function commonAxiosGetRequest<T, Q>(path: string, params?: Q, options?: CommonApiOptions): Promise<[CommonApiError, T | null]> {
	let tid;
	if (options?.showLoading) {
		// tid = toast.loading('Loading...');
	}
	try {
		const token = localStorage.getItem(TokenLocalStorageKey);
		const result = await axiosInstance.get<Q, ({ data: CommonApiResponse<T> })>(path, {
			headers: { Authorization: token ? `${token || ''}` : '' },
			params,
			...(options?.timeout ? { timeout: options.timeout } : {}),
		});
		const { code, data, message } = result?.data || {};
		if (code === ResponseStatusCode.OPERATING_SUCCESSFULLY) {
			return [null, data];
		}
		const errorMessage = (typeof data === 'string' && data ? data : message);
		return [{ message: errorMessage, code }, null];
	} catch (error: any) {
		const { code, message } = error || {};
		return [{ message: message || 'request error', code }, null];
	} finally {
		// tid && toast.dismiss(tid);
	}
}

export async function commonAxiosMock<T>(data: T, delay?: number, listLen?: number): Promise<[CommonApiError, T | null]> {
	return new Promise((resolve) => {
		setTimeout(() => {
			const result = listLen ? new Array(listLen).fill({}).map(() => data) : data;
			resolve([null, result as T]);
		}, delay || 1000);
	});
}

export default axiosInstance;