import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { Menu, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { TokenLocalStorageKey } from "utils/constant";

export interface ComponentProps {
	userInfo: any;
}

const UserDropdown: FC<ComponentProps> = (props) => {
	const history = useHistory();
	const { t } = useTranslation();

	const { userInfo } = props;
	const items: MenuProps['items'] = [
		{ key: 'logout', label: t('user.logout') },
		{ key: 'userinfo', label: t('user.profile') },
	];

	const onDropdownItemClick = (e: any) => {
		const { key } = e;
		if (key === 'logout') {
			window.localStorage.setItem(TokenLocalStorageKey, '');
			window.location.href = '/';
		} else if (key === 'userinfo') {
			history.push('/account');
		}
	}

	useEffect(() => {

	}, []);

	return (
		<Dropdown menu={{ items, onClick: onDropdownItemClick }} placement="bottom">
			<div className="cursor-pointer">{t('user.welcome')}：{userInfo.userName}</div>
		</Dropdown>

	);
};

export default UserDropdown;
