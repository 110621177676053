import React, { FC } from "react";
import { Link } from "react-router-dom";
import i18n from 'i18next';
import NcImage from "shared/NcImage/NcImage";
import { getKeyByLang } from "utils/util";


export interface Card3Props {
	post: any;
}

const Card3: FC<Card3Props> = ({ post }) => {
	const { language } = i18n;
	const { id, title, pictureUrl } = post;

	const titleText = post[getKeyByLang(language, 'title')] || post.title || '';
	const desc = post[getKeyByLang(language, 'content')] || post.content || '';

	return (
		<Link to={`/blog?id=${id}`} target="__blank">
			<div className="flex flex-col-reverse sm:flex-row sm:items-center justify-between rounded-[40px] group w-full" >
				<div className="max-w-[80%]">
					<h2 className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl line-clamp-2`}>
						{titleText}
					</h2>
					<div className="line-clamp-3" dangerouslySetInnerHTML={{ __html: desc }}>
					</div>
				</div>
				<div className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}	>
					<div className="block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16">
						<NcImage containerClassName="absolute inset-0" src={pictureUrl} alt={title} />
					</div>
				</div>
			</div>
		</Link>
	);
};

export default Card3;
