import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./Spinner.scss";
import ProgressBar from "components/ProgressBar/ProgressBar";

export interface SpinnerProps {
	className?: string;
};

const Spinner: FC<SpinnerProps> = ({ className = "" }) => {

	return (
		<div
			className={`nc-Spinner ${className}`}
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<FontAwesomeIcon
				icon={faSpinner}
				className="fa-spinner fa-spin"
				size="9x"
			/>

			<ProgressBar done="100" />
		</div>
	)
};

export default Spinner;