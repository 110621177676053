import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner/Spinner";
import Card3 from "./Card3";
import { commonAxiosPostRequest } from "utils/request";
import { getPureArray } from "utils/util";

export interface SectionLatestPostsProps {
	posts?: any[];
	className?: string;
	postCardName?: "card3";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const [postList, setPostList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleQueryDataList = async () => {
		setIsLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ list: any[] }, {}>('/universal_mall/api/app/common/blogs', {
			pageNo: 1,
			pageSize: 50,
		});
		setPostList(getPureArray(response?.list));
		setIsLoading(false);
	}

	useEffect(() => {
		handleQueryDataList();
	}, []);

	if (isLoading) {
		return (
			<div className="flex flex-col items-center">
				<Spinner className="pt-24 pb-24 lg:py-32" />
			</div>
		)
	}

	return (
		<div className={`nc-SectionLatestPosts relative ${className}`}>
			<div className="flex flex-col lg:flex-row">
				<div className="w-full  xl:pr-14">
					<h1 className="text-3xl font-bold mb-8 text-center">{t('nav.blogs')}</h1>
					<div className={`grid gap-6 md:gap-8 grid-cols-1`}>
						{postList.map((post) => <Card3 key={post.id} post={post} />)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionLatestPosts;
