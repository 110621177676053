import { FC } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./Banner.scss";

export interface BannerProps {
	className?: string;
	imageArr?: any[];
};

const Banner: FC<BannerProps> = ({ className = "", imageArr = [] }) => {

	let keys = Object.keys(imageArr);

	const createCarouselItemImage = (index: any, options = {}) => (

		<div key={index}>
			<img src={imageArr[index]} alt={index} />
		</div>
	);

	const baseChildren = <div>{keys.map(createCarouselItemImage)}</div>;

	return (
		<>
			<Carousel
				showThumbs={false}
				showStatus={false}
				swipeable={true}
				autoPlay={true}
				infiniteLoop={true}
				dynamicHeight={true}
			>
				{baseChildren.props.children}
			</Carousel>
		</>
	)
};

export default Banner;