import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { getPureArray } from "utils/util";
import Heading2 from "components/Heading/Heading2";
import { commonAxiosPostRequest } from "utils/request";
import "./index.css";

interface IContentInfo {
	videoUrl?: string;
	linkUrl?: string;
	pictureUrl?: string;
}

function ContentSelector(props: IContentInfo) {
	if (!props) {
		return null;
	}
	const { videoUrl, pictureUrl, linkUrl } = props;
	const boxType = videoUrl ? 'video' : 'picture';
	if (boxType == "video") {
		return (
			<div className="w-full h-[315px]">
				<ReactPlayer
					controls
					width="100%"
					height="100%"
					url={videoUrl}
					muted={true}
					playing={true}
				/>
			</div>
		);
	} else {
		return (
			<a href={linkUrl} className="w-full h-full">
				<img src={pictureUrl || ''} alt="" className="w-full h-full rounded-[12px]" />
			</a>
		);
	}
}

interface CarouselProps {
	heading?: string;
	leftIsImage?: boolean;
};

const PromotionBlock: FC<CarouselProps> = (props) => {
	const { t } = useTranslation();
	const [bannerList, setBannerList] = useState<any[]>([]);

	const handleQueryBannerList = async () => {
		const [error, response] = await commonAxiosPostRequest<{ list: any[] }, {}>('/universal_mall/api/app/common/discountList', {});
		const list = getPureArray<any>(response?.list);
		setBannerList(list);
		// setBannerList([
		// 	{ pictureUrl: 'http://ywca-oss.oss-cn-hongkong.aliyuncs.com/ywca/admin/2024/8/5a723e405b8111ef885c5d9f5d440c27.jpg' },
		// 	{ pictureUrl: 'http://ywca-oss.oss-cn-hongkong.aliyuncs.com/ywca/admin/2024/8/66c0e0c05b8111ef885c5d9f5d440c27.jpg' },
		// ]);
	}

	useEffect(() => {
		handleQueryBannerList();
	}, []);

	if (!bannerList.length) {
		return null;
	}

	return (
		<section className="mt-8 sm:mt-8 lg:mt-16">
			{props.heading ? <Heading2 heading={t('product.promotions')} className="mb-8 mb-0" /> : null}
			<div className="container">
				<div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
					{
						bannerList.map(item => (
							<div className="w-full" key={item.id}>
								{ContentSelector(item)}
							</div>
						))
					}
				</div>
			</div>
		</section>
	);
}


export default PromotionBlock;