import React, { FC, useState, useEffect, useContext } from "react";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import { getPureArray } from "utils/util";
import { commonAxiosPostRequest } from "utils/request";

type IRegionInfo = {
    province: string;
    city: string;
    area?: string;
    address: string;
};
export interface ComponentProps {
    /* 订单总价 */
    regionInfo: IRegionInfo;
    onChange: (value: IRegionInfo, type: 'province' | 'city' | 'address') => void;
};

type TOption = {
    value: string;
    label: string;
    children?: TOption[];
};

const ShippingAddress: FC<ComponentProps> = (props) => {
    const { t } = useTranslation();
    const { language } = i18n;
    const { regionInfo, onChange } = props;
    // 省列表
    const [provinceList, setProvinceList] = useState<TOption[]>([]);
    // 市列表
    const [cityList, setCityList] = useState<TOption[]>([]);

    const onProvinceChange = (e: any) => {
        const { value } = e.target;
        const currentCityList = provinceList.find(item => item.value === value)?.children || [];
        setCityList(currentCityList);
        onChange({
            ...regionInfo,
            province: value,
            city: currentCityList[0]?.value || '',
        }, 'province');
    };

    const onCityChange = (e: any) => {
        const { value } = e.target;
        onChange({ ...regionInfo, city: value }, 'city');
    };

    const onAreaChange = (e: any) => {
        const { value } = e.target;
    };

    const onAddressChange = (e: any) => {
        const { value } = e.target;
        onChange({ ...regionInfo, address: value }, 'address');
    }

    const handleQueryRegionList = async () => {
        const [error, response] = await commonAxiosPostRequest<{ list: TOption[] }, {}>('/universal_mall/api/app/common/region', { language });
        const list = getPureArray<TOption>(response?.list);
        setProvinceList(list);
    }

    useEffect(() => {
        handleQueryRegionList();
    }, []);

    useEffect(() => {
        if (provinceList.length > 0) {
            onProvinceChange({ target: { value: regionInfo.province || provinceList[0].value } });
        }
    }, [provinceList]);

    return (
        <>
            <div className="flex items-center">
                {/* 省 */}
                <Select className="mt-1.5" onChange={onProvinceChange} value={regionInfo.province} placeholder="请选择">
                    <option value={''}>{t('common.select_placeholder')}</option>
                    {provinceList.map((item: TOption) => {
                        return (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        );
                    })}
                </Select>
                {/* 市 */}
                <Select className="mt-1.5" onChange={onCityChange} value={regionInfo.city}>
                    <option value={''}>{t('common.select_placeholder')}</option>
                    {
                        cityList.map((item: TOption) => {
                            return (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            );
                        })
                    }
                </Select>
                {/* 区 */}
                {/* <Select className="mt-1.5" onChange={onAreaChange} value={regionInfo.area}>
                    {
                        cityList.map((item: TOption) => {
                            return (
                                <option defaultValue={item.value} key={item.value}>{item.value}</option>
                            );
                        })
                    }
                </Select> */}
            </div>
            <Input
                name="address"
                value={regionInfo.address}
                onChange={onAddressChange}
                placeholder={t('checkout.address_input_error')}
            />
        </>
    );
};

export default ShippingAddress;