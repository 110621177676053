import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { getPureArray, getNameByLang } from "utils/util";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { CartCacheLocalStorageKey } from "utils/constant";


export interface ComponentProps {
    className?: string;
    cartSkuList: any[];
    onCartSkuListChange: () => void;
};

const CartSkuItems: FC<ComponentProps> = ({ className = "", cartSkuList, onCartSkuListChange }) => {
    const { t } = useTranslation();
    const { language } = i18n;

    const handleChangeCountButtonClick = (type: 'decrease' | 'increase', sku: any, info: any) => {
        const index = cartSkuList.findIndex(item => item.productId === info.productId);
        const skuIndex = cartSkuList[index].skuList.findIndex((item: any) => item.id === sku.id);
        let quantity = cartSkuList[index].skuList[skuIndex].quantity;
        type === 'increase' ? (quantity += 1) : (quantity -= 1);
        cartSkuList[index].skuList[skuIndex].quantity = quantity;
        window.localStorage.setItem(CartCacheLocalStorageKey, JSON.stringify(cartSkuList));
        onCartSkuListChange();
    }

    return (
        <>
            {cartSkuList.map((item) => {
                let formattedTitle = (item.title || "").replaceAll(";", "");
                return (
                    <div
                        className="nc-NcInputNumber w-full listingSection__wrap"
                        data-nc-id="NcInputNumber"
                        key={item.productId}
                        style={{ padding: "12px" }}
                    >
                        <span className="font-medium text-neutral-800 dark:text-neutral-200">{formattedTitle}</span>
                        {item.skuList.map((sku: any) => {
                            return (
                                <div className="flex" style={{ margin: "10px 0" }} key={sku.id}>
                                    <div style={{ width: "80%", marginLeft: "12px" }}>
                                        <div className="flex">
                                            <img src={sku?.pictureUrl || ''} alt={sku?.[getNameByLang(language)] || ''} className="w-[80px] h-[80px] shrink-0 mr-[12px]" />
                                            <div className="">
                                                <div className="text-[16px]"> {sku?.[getNameByLang(language)] || '' || sku?.name || ''}</div>
                                                <div className="text-[16px] font-semibold mt-[4px]">{t('cart.price')}：{`HKD $${sku?.sellingPrice || ''}`}</div>
                                                <div className="text-[16px] font-semibold mt-[4px]">{t('cart.total_amount')}：{`HKD $${sku.originTotalPrice}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`flex items-center justify-between`} style={{ width: "11rem" }}>
                                        <div className={`nc-NcInputNumber flex items-center justify-between w-28`}>
                                            <button
                                                className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                                type="button"
                                                onClick={() => handleChangeCountButtonClick('decrease', sku, item)}
                                            >
                                                <MinusIcon className="w-4 h-4" />
                                            </button>
                                            <span>{sku.quantity || 0}</span>
                                            <button
                                                className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                                                type="button"
                                                onClick={() => handleChangeCountButtonClick('increase', sku, item)}
                                            >
                                                <PlusIcon className="w-4 h-4" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/* <SubtotalRender apiBreakdown={apiBreakdown} id={item.id} /> */}
                    </div>
                );
            })}
        </>
    );
};

export default CartSkuItems;