import React, { FC, useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/authenticate";

export interface ComponentProps { }

const VipRender: FC<ComponentProps> = () => {
	const { userInfo } = useContext(AuthContext);
	const { t } = useTranslation();

	if (userInfo?.userType !== 1) {
		return null;
	}

	if (userInfo?.vipFlag === 1) {
		return (
			<>
				<h2 className="text-3xl font-semibold">VIP</h2>
				<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
				<div className="max-w-md bg-gradient-to-r from-yellow-400 to-yellow-500 border border-yellow-300 rounded-lg shadow-lg p-6 text-white">
					<h2 className="text-2xl font-bold mb-4">{t('user.vipName')}</h2>
					<div className="flex items-center mb-4">
						<div className="bg-yellow-600 rounded-full h-12 w-12 flex items-center justify-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M12 15.75l-3.75-3.75m0 0l3.75-3.75m-3.75 3.75h7.5M21 21H3a2.25 2.25 0 01-2.25-2.25V5.25A2.25 2.25 0 013 3h18a2.25 2.25 0 012.25 2.25v13.5A2.25 2.25 0 0121 21z" />
							</svg>
						</div>
						<div className="ml-4">
							<h3 className="text-lg font-semibold">{t('user.vipIs')}</h3>
							<p className="text-sm">{t('user.vipThanks')}</p>
						</div>
					</div>
					<div className="text-sm">
						<p className="mb-2"><span className="font-semibold">{t('user.vipForever')}</span>{t('user.vipForeverTip')}</p>
						<p><span className="font-semibold">{t('user.vipDiscount')}</span>{t('user.vipDiscountMore')}</p>
						<p className="mb-2"><span className="font-semibold">{t('user.vipEffectiveTime')}</span> {userInfo?.vipEffectiveTime || ''}</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<h2 className="text-3xl font-semibold">VIP</h2>
			<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
			<div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-lg p-6">
				<h2 className="text-2xl font-bold text-gray-800 mb-4">{t('user.progressTitle')}</h2>
				<div className="mb-4">
					<p className="text-gray-700">{t('user.progressUpgrade')}<span className="font-semibold">{userInfo?.vipProgressInfo?.vipThresholdAmount || 0} HKD</span></p>
					<p className="text-gray-700">{t('user.progressCurrent')}<span className="font-semibold text-green-600">{userInfo?.vipProgressInfo?.totalConsumptionAmount || 0} HKD</span></p>
					<p className="text-gray-700">{t('user.progressNeed')} <span className="font-semibold text-red-600">{userInfo?.vipProgressInfo?.amountToVip || 0} HKD</span></p>
				</div>
				<div className="relative pt-1">
					<div className="overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-200">
						<div style={{ width: `${userInfo?.vipProgressInfo?.vipProgress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
					</div>
				</div>
				<div className="text-sm text-gray-600 italic">
					* {t('user.vipTips')}
				</div>
			</div>
		</>

	);
};

export default VipRender;
