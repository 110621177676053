import React from "react";
import SectionLatestPosts from "./SectionLatestPosts";

const BlogPage: React.FC = () => {
	return (
		<div className="nc-BlogPage overflow-hidden relative">
			<div className="container relative">
				<SectionLatestPosts className="py-16 lg:py-28" />
			</div>
		</div>
	);
};

export default BlogPage;
