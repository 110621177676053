// 支付方式
export const PaymentOptions = [
    { label: "信用卡", labelEn: 'Credit Card', labelHk: '信用卡', value: "CreditCard" },
    { label: "微信", labelEn: 'Wechat', labelHk: '微信', value: "Wechat" },
    { label: "支付宝", labelEn: 'Alipay', labelHk: '支付寶', value: "Alipay" },
    { label: "Fps", labelEn: 'Fps', labelHk: 'Fps', value: "Fps" },
];


export const BuyChInfoList = [
    { label: "請選擇", value: "" },
    { label: "Facebook / Instagram", value: "Facebook / Instagram" },
    { label: "展覽會", value: "展覽會" },
    { label: "舊有客戶", value: "舊有客戶" },
    { label: "HKTVMALL", value: "HKTVMALL" },
    { label: "網上搜尋", value: "網上搜尋" },
    { label: "朋友介紹", value: "朋友介紹" },
    { label: "其他〈請註明〉", value: "其他〈請註明〉" },
];
