import React, { useContext } from "react";
import { AuthContext } from "context/authenticate";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import Logo from "shared/Logo/Logo";
import SocialsList from "shared/SocialsList/SocialsList";
import { CustomLink } from "data/types";
import companyLogo from "images/logo.png";
import { getNameByLang } from "utils/util";
import promotionBottomImg1 from "images/promotion-bottom-1.jpg";
import promotionBottomImg2 from "images/promotion-bottom-2.jpg";

export interface WidgetFooterMenu {
	id: string;
	title: string;
	menus: CustomLink[];
}

const Footer: React.FC = () => {
	const { t } = useTranslation();
	const { productTypeList } = useContext(AuthContext);
	const { language } = i18n;

	const year = new Date().getFullYear();


	return (
		<div className="nc-Footer mt-8 sm:mt-8 lg:mt-16 border-t border-neutral-200 dark:border-neutral-700">
			<div className="container">
				<div className="py-8 flex flex-col flex-wrap items-center justify-center sm:flex-row sm:justify-between">
					<Logo img={companyLogo} homePageUrl={'/'} />
					<div className="flex items-center flex-wrap justify-center mt-4 sm:mt-0">
						<div className="mr-4 mb-4 sm:mb-0">
							<img src={promotionBottomImg1} className="w-[240px] max-w-[240px] h-[150px]" alt="" />
						</div>
						<div className="mr-4 mb-4 sm:mb-0">
							<img src={promotionBottomImg2} className="w-[240px] max-w-[240px] h-[150px]" alt="" />
						</div>
					</div>
				</div>
				<div className="py-8 border-t border-neutral-200 dark:border-neutral-700 flex items-start flex-wrap">
					<div className="flex flex-col mr-24 mb-4 sm:mb-0">
						<div className="mb-4 text-[20px] font-bold leading-[22px] text-left">{t('footer.categroies')}</div>
						{
							productTypeList.map(item => (
								<a key={item.id} className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href={`/category/${item.id}`} target="__blank">
									{item[getNameByLang(language)] || item.name || ''}
								</a>
							))
						}
					</div>
					<div className="flex flex-col mr-24 mb-4 sm:mb-0">
						<div className="mb-4 text-[20px] font-bold leading-[22px] text-left">{t('footer.more')}</div>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="/about" target="__blank">{t('nav.about')}</a>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="/services#deliveryPolicy" target="__blank">{t('nav.delivery_policy')}</a>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="/services" target="__blank">{t('nav.service')}</a>
					</div>
					<div className="flex flex-col mr-24">
						<div className="mb-4 text-[20px] font-bold leading-[22px] text-left">{t('footer.contact')}</div>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="mailto:order@ifhkltd.com">{t('checkout.email')}: order@ifhkltd.com</a>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="tel:+85235639187">HK: +852 3563 9187</a>
						<a className="inline-block text-[#6F6C90] mt-4 text-[18px] font-normal leading-[20px] text-left" href="tel:++8617179955357">China: +86 171 7995 5357</a>
					</div>
				</div>
				<div className="py-8 border-t border-neutral-200 dark:border-neutral-700 flex items-center justify-between flex-wrap">
					<div className="flex text-[14px] justify-center text-center text-neutral-6000 dark:text-neutral-300">
						Copyright {year} by Integrity First Holding Limited. All Rights Reserved.
					</div>
					<div className="w-full sm:w-auto flex justify-center mt-4 sm:mt-0">
						<SocialsList className="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
