import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "context/authenticate";
import { getPureArray } from "utils/util";

export interface ComponentProps {
    className?: string;
};

const CouponCardRender: FC<ComponentProps> = (props) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AuthContext);

    if (!userInfo?.id) {
        return (
            <div className="flex items-center">
                <div className="text-gray-400 text-sm italic">{t('coupon.useWay')}</div>
                <ButtonPrimary href="/login" className="ml-[12px]" sizeClass="px-2 py-1 sm:px-3">{t('coupon.login')}</ButtonPrimary>
            </div>
        );
    }

    if (getPureArray(userInfo?.couponList).length > 0) {
        return (
            <div className="w-full bg-white border border-gray-300 rounded-lg shadow-lg p-4">
                <div className="flex items-center">
                    <div className="bg-green-100 text-green-600 rounded-full h-10 w-10 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9V4.5a.75.75 0 011.5 0V9h3.75a.75.75 0 010 1.5H12.75v4.25a.75.75 0 11-1.5 0V10.5H7.5a.75.75 0 010-1.5h3.75z" />
                        </svg>
                    </div>
                    <div className="ml-4">
                        <h2 className="text-xl font-bold text-gray-800">{t('coupon.useable')}</h2>
                        <p className="text-gray-600">{userInfo?.couponList?.[0]?.couponName}：<span className="font-semibold text-green-600">{userInfo?.couponList?.[0]?.couponCode}</span></p>
                    </div>
                </div>
                <div className="mt-4">
                    <p className="text-sm text-gray-700">
                        {t('coupon.useTip1')}
                        {t('coupon.useTip2')}
                    </p>
                </div>
            </div>
        );
    }

    return null;

};

export default CouponCardRender;